.client-review-container {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.client-review-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
}

.client-review-initials {
  font-family: 'Fraunces', serif;
  font-size: min(1.5rem, 10vw);
  width: 15vw;
  max-width: 75px;
  height: 15vw;
  max-height: 75px;
  border-radius: 50%;
  background-color: var(--color-green);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-review-date {
  font-weight: 600;
  text-transform: uppercase;
}

.client-review-right {
  min-width: 200px;
  max-width: 650px;
  display: flex;
  flex-flow: column nowrap;
}

.client-review-forward-tick {
  text-align: left;
}

.client-review-back-tick {
  text-align: right;
}

.client-review-text {

}

.client-review-text > p > strong {
  color: var(--color-violet);
}

.client-review-tick {
  color: var(--color-green);
}
