.about-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 0 var(--horizontal-outer-padding) var(--bottom-content-padding);
}

.about-photo {
  width: var(--single-photo-width);
}

.about-text-emphasized {
  font-weight: bold;
  color: var(--primary-color);
}

.about-photo-banner {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90vw;
  padding: 1rem 0rem;
  margin-left: var(--horizontal-negative-outer-padding);
}

.about-photo {
  width: 30%;
}

.about-photo-row {
  padding: 0 -10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  max-width: 80vw;
  gap: 0.5rem;
  width: 100%;
}

.about-photo-row-photo {
  width: min(20vw, 300px);
  flex-shrink: 1;
}

.about-activities-container {
  display: flex;
  text-align: left;
  flex-flow: row wrap;
}
