.page-container {
  padding: 2rem var(--horizontal-outer-padding);
  text-align: center;
}

.page-header-container {
  padding: 2rem var(--horizontal-outer-padding);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: end;
  gap: 1rem;
}

.page-header-logo-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
}

.logo {
  max-height: 4rem;
  cursor: pointer;
}

.nav-bar {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding-top: 0.5rem;
}

.nav-link {
  font-family: 'Karla', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-green);
  text-decoration: underline;
}

.page-footer-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.page-footer-cta {
  background-color: var(--color-tan);
  color: var(--dark-color);
  border-top: 20px solid var(--color-blue);

  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}

.page-footer-cta-text {
  font-size: min(1rem, 16px);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  text-align: right;
}

.hoan-graphic-container {
  z-index: -1;
}

.hoan-graphic {
  min-width: 450px;
  width: 120vw;
}

.page-footer-logos {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.page-footer-lsr-logo {
  max-width: 100px;
}

.page-footer-realtor-logo {
  max-width: 50px;
}
