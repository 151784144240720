.testimonials-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 0 var(--horizontal-outer-padding) var(--bottom-content-padding);
}

.testimonials-photo-row {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90vw;
  padding: 1rem 0rem;
}

.testimonials-photo {
  width: 30%;
}

.testimonial-link {
  background-color: var(--color-violet);
  color: var(--color-white);

  padding: 1rem;
  text-decoration: none;
  font-weight: bold;
}
