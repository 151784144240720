body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Fraunces';
  src: url('./assets/Fraunces/Fraunces-VariableFont_SOFT\,WONK\,opsz\,wght.ttf')
    format('truetype');
  /* font-weight: 400; */
}

@font-face {
  font-family: 'Karla';
  src: url('./assets/Karla/Karla-VariableFont_wght.ttf') format('truetype');
  /* font-weight: 400; */
}

:root {
  --primary-color: #953759;
  --secondary-color: #93b7be;
  --tertiary-color: #767522;
  --dark-color: #2f3e46;
  --light-color: #fffbf5;
  /* --black-color: #2F3E46;
  --white-color: #FFFBF5; */
  --color-yellow: #ecc659;
  --color-orange: #f79d25;
  --color-red: #f06449;
  --color-violet: #953759;
  --color-green: #767522;
  --color-brown: #584c2c;
  --color-blue: #93b7be;
  --color-navy: #2f3e46;
  --color-tan: #e7d7c1;
  --color-white: #fffbf5;

  --header-left-spacing: 4rem;
  --header-left-negative-spacing: -1rem;

  --vertical-content-gap: 8vw;
  --horizontal-outer-padding: 5vw;
  --horizontal-negative-outer-padding: -5vw;
  --bottom-content-padding: 4rem;

  --single-photo-width: min(15rem, 300px);
}

body {
  font-family: 'Karla', sans-serif;
  background-color: var(--color-white);
  color: var(--color-navy);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Fraunces', serif;
}

h1 {
  background-color: var(--color-blue);
  color: var(--light-color);
  text-align: center;
  font-weight: 600;
  padding: 0.25rem 0;
  margin: 2rem 0 4rem -5vw;
  width: 100vw;
}

h2 {
  color: var(--color-violet);
  text-shadow: var(--color-tan) 1px 1px 0;
}

a {
  color: var(--dark-color);
}

.link {
  color: var(--light-color);
  padding: 1rem;
  background-color: var(--color-orange);
  text-decoration: none;
  font-weight: bold;
}

button {
  background-color: var(--color-orange);
  border: 2px solid var(--color-tan);
  border-radius: 0.25rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  box-shadow: 1px 1px 0 0 var(--color-tan);
  cursor: pointer;
}
